import { inject, onMounted, onUnmounted } from '@vue/composition-api'

export function useDashboardView() {
  const dashboardView = inject('dashboardView')

  const mounted = () => {
    onMounted(() => {
      if (dashboardView) {
        dashboardView.paddingIsRemoved = true
      }
    })
  }

  const unmounted = () => {
    onUnmounted(() => {
      if (dashboardView) {
        dashboardView.paddingIsRemoved = false
      }
    })
  }

  return {
    dashboardView,
    mounted,
    unmounted,
  }
}
