import { ref, computed } from '@vue/composition-api'
export function useIframeLoader() {
  const isIframeLoading = ref(true)
  const onIframeLoaded = () => {
    isIframeLoading.value = false    
  }

  const loadIframe = () => {
    isIframeLoading.value = true
  }

  return {
    isIframeLoading: computed(() => isIframeLoading.value),
    onIframeLoaded,
    loadIframe,
  }
}
